<template>
  <Home/>
</template>

<script>
import Home from './components/Home.vue'
import './assets/css/style.scss'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
</style>
