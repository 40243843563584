<script setup>
import {defineAsyncComponent, ref, watch} from 'vue';

const campaignId = ref(null);
const type = ref('single-entry-evisa-7');
const rcToken = ref(null);
const $form = ref(null);
const onClose = ref(null);
const isSubmit = ref(false);

const getCampaignFromHash = () => {
  const queryString = window.location.href.replace(/^http.+\?/, '?');
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('campaignid');
}
const onSubmit = () => {
  if (rcToken.value && campaignId.value && !isSubmit.value) {
    isSubmit.value = true;
    const interval = setInterval(() => {
      if ($form.value.rc_token.value && $form.value.type.value) {
        clearInterval(interval);
        $form.value.submit();
      }
    }, 5);
  }
}

const HomeWithContent = ref(null);

watch([rcToken, campaignId], onSubmit);

window.addEventListener('hashchange', () => {
  const campId = getCampaignFromHash();

  if (campId) {
    localStorage.setItem('campaignId', campId);
    campaignId.value = campId;
  }
});
const interval = setInterval(() => {
  if (window.grecaptcha) {
    clearInterval(interval)

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute('6LfDE_wgAAAAAFdt_xSW0i9qxYuNuSI0sD1JohgU', {action: 'application'})
          .then((token) => {
            rcToken.value = token
          });
    });
  }
}, 10);


if (localStorage) {
  let campaiId = localStorage.getItem('campaignId');
  if (campaiId) {
    campaignId.value = campaiId;
  } else {
    const queryString = window.location.href.replace(/^http.+\?/, '?');
    const urlParams = new URLSearchParams(queryString);
    campaiId = urlParams.get('campaignid');

    if (campaiId) {
      localStorage.setItem('campaignId', campaiId);
      campaignId.value = campaiId;
    } else {
      HomeWithContent.value = defineAsyncComponent(() =>
          import('@/components/HomeWithContent.vue')
      );
    }
  }
} else {

  let campaiId = getCampaignFromHash();

  if (campaiId) {
    campaignId.value = campaiId;
  } else {
    HomeWithContent.value = defineAsyncComponent(() =>
        import('@/components/HomeWithContent.vue')
    );
  }
}
</script>

<template>
  <div>
    <template v-if="HomeWithContent!==null">
      <HomeWithContent/>
    </template>
    <template v-else>
      <div style="height: 100vh; width: 100vw;display: flex;align-items: center;justify-content: center">
        <div style="text-align: center">
          <img src="@/assets/images/icons8-loading.gif" alt="icon-loading"> <br>
          <span>Please wait...</span>
        </div>
      </div>
    </template>

    <form ref="$form" action="/api/application" method="post">
      <input type="hidden" name="type" :value="type"/>
      <input type="hidden" name="site" value="kenya">
      <input name="rc_token" :value="rcToken" type="hidden"/>
      <input type="hidden" name="on_close" :value="onClose"/>
    </form>
  </div>
</template>